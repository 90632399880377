import { NgSelectComponent } from '@ng-select/ng-select';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { AttachmentManagerListComponent } from '@shared/modules/attachment-manage/components/attachment-manager-list/attachment-manager-list.component';
import { SnackBarService } from '@core/services/snackbar.service';
import { Config } from '@shared/configs/config';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { TranslateService } from '@ngx-translate/core';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { MailingHttpService } from '@shared/modules/mailings/shared/services/mailing-http.service';
import { finalize, tap, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
  OnDestroy
} from '@angular/core';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config';
import { Regex } from '@shared/configs/regex';
import { MailingQuillFormats } from './data/mailing-quill-formats.data';
import { MailingQuillModules } from './data/mailing-quill-modules.data';
import { Subscription, of } from 'rxjs';
import { MailingForm } from '@shared/modules/mailings/shared/enums/mailing-form.enum';
import { MailingTemplate } from '../../models/mailing-template.model';
import { EMailingStates, MailingService } from '@shared/modules/mailings/shared/services/mailing.service';
import { addStyleToParagraphs } from '@shared/helpers/add-styles.herlper';
import { ProjectCreatorEntityDiscardComponent } from '@modules/project-creator/shared/modules/project-creator-entity-sidenav/components/project-creator-entity-discard/project-creator-entity-discard.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Component({
  selector: 'app-mailing-sidenav',
  templateUrl: './mailing-sidenav.component.html',
  styleUrls: ['./mailing-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingSidenavComponent implements OnInit, OnDestroy {
  readonly cssStyles = {
    p: 'padding:0;margin:0;',
    ul: 'padding-top:0;padding-bottom:0;margin-top:0;margin-bottom:0;',
    ol: 'padding-top:0;padding-bottom:0;margin-top:0;margin-bottom:0;',
    blockquote:
      'border-left: 4px solid #ccc;margin-bottom: 5px;margin-top: 5px;padding-left: 16px;margin-left:0;'
  };

  static componentName: string = 'MailingSidenavComponent';

  readonly MailingForm = MailingForm;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTypes = ButtonTypes;

  @ViewChild('tooltip') tooltip: MatTooltip;
  @ViewChild('attachmentsList') attachmentsList: AttachmentManagerListComponent;

  form: FormGroup;
  sendingTemplate: boolean = false;
  loadingTemplate: boolean = false;
  initialEmails: any[] = [];
  firstLoad: boolean = false;

  sub: Subscription = new Subscription();

  attachmentsConfig: TextAttachmentsConfig = {
    fileAttachments: true,
    photoAttachments: true,
    videoAttachments: true,
    maxAttachmentSize: 50
  };

  template: MailingTemplate;
  modules = MailingQuillModules(this.uploadHandler.bind(this), this.imageHandler.bind(this));
  formats: string[] = MailingQuillFormats;

  projectId: number;
  changesSummary: any;
  @ViewChild('participants') participants: NgSelectComponent;

  get formData() {
    const formValue = Object.assign({}, this.form.value);
    formValue.emails = formValue[MailingForm.RECIEVER];
    formValue.projectId = formValue[MailingForm.PROJECT];
    formValue.attachmentPacketId = this.attachmentsList.service.attachmentPacket?.id || null;

    delete formValue[MailingForm.PROJECT];
    delete formValue[MailingForm.RECIEVER];
    delete formValue[MailingForm.ATTACHMENTS];
    return formValue;
  }

  get recieverIds() {
    return this.sideData.data?.recieverIds ?? [];
  }

  get ContactsUrl() {
    const ids = this.recieverIds?.map((id: number) => `requiredIds[]=${id}`).join('&');
    return `${Config.API}/contact/search?emailsOnly=1${ids ? '&' + ids : ''}`;
  }

  get formattedText() {
    return this.form.controls[MailingForm.DESCRIPTION].value;
  }

  get errorMessages() {
    return Config.validationMessages;
  }

  get isDigitalChanges(): boolean {
    return !!(this.template?.number === 25);
  }

  get taskIds(): number[] {
    return this.sideData?.data?.taskIds || [];
  }

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    private fb: FormBuilder,
    private changes: ChangeDetectorRef,
    private http: MailingHttpService,
    private t: TranslateService,
    private s: SnackBarService,
    private sideService: SidenavService,
    private mailingService: MailingService,
    private dialog: MatDialog,
    private active: ActivatedRoute
  ) {}

  ngOnInit() {
    this._createForm();
    this._setInitialData();
    this._quillChangeDetector();
    this._subscribeProjectChange();
  }

  private _quillChangeDetector() {
    const sub = this.form.get(MailingForm.DESCRIPTION).valueChanges.subscribe(() => {
      this.changes?.detectChanges();
    });
    this.sub.add(sub);
  }

  private _subscribeProjectChange() {
    const sub = this.form.get(MailingForm.PROJECT).valueChanges.subscribe((project) => {
      this.projectId = project;
      this._getTemplate();
    });
    this.sub.add(sub);
    this.form.get(MailingForm.PROJECT).updateValueAndValidity();
  }

  isControlInvalid(name: string) {
    return this.form?.controls[name]?.touched && this.form?.controls[name]?.invalid;
  }

  private _setInitialData() {
    this.projectId = Number(this.sideData?.data?.projectId) || null;
    this.form.get(MailingForm.PROJECT).setValue(this.projectId);
  }

  private _getTemplate() {
    this.loadingTemplate = true;
    this.changes.detectChanges();

    const id = this.sideData?.data?.templateId;
    const modalData = JSON.parse(this.active?.snapshot?.queryParams['modalData']);

    this.http
      .getEmailTemplateById(id, this.projectId, modalData?.templateId === 25)
      .pipe(tap((template) => (this.template = template)))
      .pipe(tap(() => this._fillTemplate(this.template)))
      .pipe(
        switchMap(() =>
          this.isDigitalChanges
            ? this.http
                .getProjectChanges(this.projectId, this.taskIds)
                .pipe(tap((changesSummary) => (this.changesSummary = changesSummary)))
            : of({})
        )
      )
      .pipe(
        finalize(() => {
          this.loadingTemplate = false;
          this.changes.detectChanges();
        })
      )
      .subscribe();
  }

  private reloadTemplateFactory() {
    //todo template factory

    if (!this.firstLoad) {
      this.firstLoad = true;
      const email = this.sideData?.data?.email || null;
      if (email) {
        this.initialEmails = [this.createEmailTag(email)];
        const _email = this.isDigitalChanges ? email : [email];
        setTimeout(() => this.form.get(MailingForm.RECIEVER).setValue(_email), 0);
      }

      if (!!this.recieverIds?.length) {
        setTimeout(() => {
          const items = this.participants?.itemsList?.items?.filter((i: any) => {
            return this.recieverIds?.indexOf(i?.value?.id) > -1;
          });
          console.log(items, this.recieverIds);
          this.form
            .get(MailingForm.RECIEVER)
            .setValue(items.map((i: any) => i?.value?.email).filter(Boolean));
        }, 100);
      }
    }

    const content = this.template.content;
    const separator = '[Podgląd zmian]';
    const header = content.split(separator)[0] || null;
    const footer = content.split(separator)[1] || null;

    this.form.get(MailingForm.DESCRIPTION).setValue(header);
    this.form.get(MailingForm.DESCRIPTION_END).setValue(footer);
  }

  private _createForm() {
    this.form = this.fb.group({
      [MailingForm.PROJECT]: [this.projectId],
      [MailingForm.NAME]: [null],
      [MailingForm.RECIEVER]: [null, [Validators.required]],
      [MailingForm.TITLE]: [null, [Validators.required]],
      [MailingForm.DESCRIPTION]: [null, [Validators.required]],
      [MailingForm.DESCRIPTION_END]: [null],
      [MailingForm.ATTACHMENTS]: []
    });
  }

  private _fillTemplate(template) {
    this.form.get(MailingForm.NAME).setValue(template.name);
    this.form.get(MailingForm.TITLE).setValue(template.title);

    const controls = [MailingForm.NAME, MailingForm.TITLE, MailingForm.DESCRIPTION];
    controls.forEach((controlName: string) => this.form.get(controlName).markAsPristine());
    this.reloadTemplateFactory();
  }

  showTooltip() {
    setTimeout(() => {
      this.tooltip.show();
      setTimeout(() => this.tooltip.hide(), 600);
    }, 0);
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    const name = $event;
    return this.createEmailTag(name);
  }

  createEmailTag(name: string) {
    return { id: name, name, newItem: true, fullName: name, email: name };
  }

  successOnLoadContacts({ records }: { records: Contact[] }) {
    return [...records.map((c) => new Contact(c))];
  }

  changePromiseFunc($event) {
    const controls = [MailingForm.NAME, MailingForm.TITLE, MailingForm.DESCRIPTION];
    const pristine = controls.filter((controlName: string) => !!this.form.get(controlName).pristine);

    return controls.length !== pristine.length
      ? confirm(this.t.instant('Mailings.Templates.confirmation'))
      : true;
  }

  filesUploaded($event) {
    console.log(this.attachmentsList);
  }

  uploadHandler(file) {
    return new Promise((resolve, reject) => {
      // if (!this.isDigitalChanges) {
      this.attachmentsList.onFilesChange([file]);
      // }
      reject('Unsupported');
    });
  }

  imageHandler(imageDataUrl, type, imageData) {
    // if (this.isDigitalChanges) return;
    const file = imageData.toFile();
    this.attachmentsList.onFilesChange([file]);
  }

  discardSaving() {
    if (this.form.pristine) {
      this.closeSidenav();
    } else {
      this.dialog.open(ProjectCreatorEntityDiscardComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false,
        data: {
          confirm: this.closeSidenav.bind(this)
        }
      });
    }
  }

  closeSidenav() {
    this.sideService.close();
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.changes.detectChanges();
    if (this.form.invalid) return;

    if (!this.isDigitalChanges) {
      this.sendTemplate();
    } else {
      this.sendChanges();
    }
  }

  sendChanges() {
    const contentHeader = addStyleToParagraphs(
      this.form.get(MailingForm.DESCRIPTION).value || ' ',
      this.cssStyles
    );
    const contentFooter = addStyleToParagraphs(
      this.form.get(MailingForm.DESCRIPTION_END).value || ' ',
      this.cssStyles
    );

    const formValue = {
      email: this.form.get(MailingForm.RECIEVER).value,
      title: this.form.get(MailingForm.TITLE).value,
      contentHeader: contentHeader,
      contentFooter: contentFooter,
      tasksIds: this.taskIds, //get from data
      projectId: this.projectId, // get from data
      attachmentPacketId: this.attachmentsList.service.attachmentPacket?.id || null
    };

    this.sendingTemplate = true;
    this.changes.detectChanges();
    this.http
      .shareChange(formValue)
      .pipe(
        finalize(() => {
          this.sendingTemplate = false;
          this.changes.detectChanges();
          this.mailingService.mailingSubject.emit({ action: EMailingStates.EMAIL_SENT });
        })
      )
      .subscribe({
        next: () => this.successSendingMsg(),
        error: (err: HttpError) => this.errorSendingMsg(err)
      });
  }

  sendTemplate() {
    this.sendingTemplate = true;
    this.changes.detectChanges();
    const formData = this.formData;
    formData.content = addStyleToParagraphs(formData.content, this.cssStyles);
    this.http
      .sendProjectStageEmail(formData)
      .pipe(
        finalize(() => {
          this.sendingTemplate = false;
          this.changes.detectChanges();
        })
      )
      .subscribe({
        next: () => this.successSendingMsg(),
        error: (err: HttpError) => this.errorSendingMsg(err)
      });
  }

  successSendingMsg() {
    this.sideService.close();
    this.s.success(this.t.instant('Mailings.Templates.successMessageSending'));
  }

  errorSendingMsg(err: HttpError) {
    switch (err.messageCode) {
      case IntilioCodes.GOOGLE_AUTH_EXPIRED:
        this.s.error(this.t.instant('Mailings.Templates.googleAuthExpired'));
        break;
      default:
        this.s.error(this.t.instant('Mailings.Templates.errorMessageSending'));
        break;
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
