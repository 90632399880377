<modal-title
  [title]="'ProjectContacts.Add.title' | translate"
  [closeCallback]="cancel.bind(this)"
></modal-title>

<form [formGroup]="form" class="project-contacts-add-modal">
  <mat-dialog-content
    [ngClass]="{ 'project-contacts-add-modal--min-height': contactSearchSelect?.select?.isOpen }"
  >
    <div class="mt-2">
      <ng-select
        ngSelectExtension
        #contactSearchSelect="ngSelectExtensionDir"
        [url]="
          Config.API + '/contact/search?excludeProjects=1&projectIds[]=' + projectPreviewService?.project?.id
        "
        class="w-100 select select-default"
        [placeholder]="'ProjectContacts.Add.searchPlaceholder' | translate"
        [loadingText]="'Contacts.Manage.loadingContacts' | translate"
        [notFoundText]="'Contacts.Manage.notFoundContacts' | translate"
        [addTagText]="'Contacts.Manage.addContact' | translate"
        [formControlName]="'contact'"
        [bindLabel]="'name'"
        [hideSelected]="true"
        [bindValue]="'id'"
        [multiple]="false"
        [closeOnSelect]="true"
        [addTag]="onCreateContact.bind(this)"
        [tabIndex]="-1"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <contact-option-template [contact]="item"></contact-option-template>
        </ng-template>
      </ng-select>
      <span
        *ngIf="form?.get('contact')?.touched && !form?.get('contact')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'ProjectContacts.Add.notChosenContact' | translate"
      ></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">
      <app-button
        [disabled]="loading"
        (onClick)="cancel()"
        [config]="{
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'ProjectContacts.Add.cancel' | translate,
          size: ButtonSize.LARGE
        }"
      >
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'ProjectContacts.Add.save' | translate,
          size: ButtonSize.LARGE,
          prependSrc: './assets/img/ic-save.svg'
        }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
