import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EParticipantStatus } from '@shared/modules/event-sidenav/enums/participant-status.enum';

@Component({
  selector: 'participant-status',
  templateUrl: './participant-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipantStatusComponent {
  statusIcon: string;

  @Input()
  set status(status: EParticipantStatus) {
    this.setStatusIcon(status);
  }

  private setStatusIcon(status: EParticipantStatus): void {
    switch (status) {
      case EParticipantStatus.STATUS_ATTENDANCE_CONFIRMED:
        this.statusIcon = 'ic-confirmed';
        break;
      case EParticipantStatus.STATUS_ATTENDANCE_REJECTED:
        this.statusIcon = 'ic-rejected';
        break;
      default:
        this.statusIcon = 'ic-pending';
        break;
    }
  }
}
