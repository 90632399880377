import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Regex } from '@shared/configs/regex';
import { ConfirmationDiscardModalComponent } from '../confirmation-discard-modal/confirmation-discard-modal.component';

@Component({
  selector: 'contact-add-to-system',
  templateUrl: './contact-add-to-system.component.html',
  styleUrls: ['./contact-add-to-system.component.scss']
})
export class ContactAddToSystemComponent implements OnInit {
  CONFIG = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  form: FormGroup;
  loading: boolean = false;
  contact: Contact;
  roles: { id: string; text: string }[] = [];

  @Output('onAddContactToSystem') onAddContactToSystem: EventEmitter<Contact> = new EventEmitter<Contact>();
  @ViewChild('roleSelect') roleSelect: NgSelectExtensionDirective;

  constructor(
    public dialogRef: MatDialogRef<ContactAddToSystemComponent>,
    private projectApiService: ProjectAPIService,
    private s: SnackBarService,
    private t: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setRoles();
    this.createForm();
  }

  setRoles() {
    Object.keys(UserRoles).forEach((key) => {
      this.roles.push({
        id: UserRoles[key],
        text: this.t.instant('ROLES.' + key)
      });
    });
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl(this.contact.email, [
        Validators.required,
        Validators.pattern(Regex.email),
        Validators.maxLength(100)
      ]),
      role: new FormControl(null)
    });
  }

  cancel(): void {
    if (this.form.pristine) {
      this.dialogRef.close();
    } else {
      let dialogRef = this.dialog.open(ConfirmationDiscardModalComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        switch (confirmed) {
          case true:
            {
              this.dialogRef.close();
            }
            break;
          default:
            break;
        }
      });
    }
  }

  addContactToSystem() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.projectApiService
      .inviteNewContactToProject(
        this.contact.id,
        this.form.get('email').value,
        this.form.get('role').value,
        true,
        null
      )
      .subscribe({
        next: (res: Contact) => {
          this.s.success(this.t.instant('Contacts.Preview.successAddingContactToSystem'));
          this.contact = new Contact(res);
          this.contact.email = this.form.get('email').value;
          this.onAddContactToSystem.emit(this.contact);
          this.form.reset();
          this.dialogRef.close();
        },
        error: () => {
          this.s.error(this.t.instant('Contacts.Preview.errorAddingContactToSystem'));
        }
      })
      .add(() => {
        this.loading = false;
      });
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    return messages;
  }
}
