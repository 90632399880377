import { WindowHelper } from '@shared/helpers/window.helper';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { ContactServiceV2 } from '../../services/contact-v2.service';
import { take } from 'rxjs/operators';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';

export type ContactSidenavTab = 'details' | 'connections' | 'projects' | 'commentaries';

@Component({
  styleUrls: ['./contact-sidenav.component.scss'],
  templateUrl: './contact-sidenav.component.html'
})
export class ContactSidenavComponent implements OnInit, OnDestroy {
  static componentName: string = 'ContactSidenavComponent';

  contact: Contact;
  isEdit: boolean;
  selectedTab?: ContactSidenavTab;

  WindowHelper = WindowHelper;

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    private service: ContactServiceV2,
    private contactManageService: ContactManagePanelService
  ) {}

  get showLoading(): boolean {
    return this.isEdit && !this.contact;
  }

  get showContactPanel(): boolean {
    return !this.isEdit || !!this.contact;
  }

  ngOnInit(): void {
    this.handleInitialData();
  }

  private handleInitialData(): void {
    const { id, selectedTab, isPreview } = this.sideData.data || {};
    this.isEdit = !!id;
    if (this.isEdit) {
      this.service
        .getContact(id)
        .pipe(take(1))
        .subscribe((contact) => {
          this.contact = contact;
          this.contactManageService.setContactData(contact);
          this.contactManageService.setIsPreview(isPreview ?? false);
        });
      this.selectedTab = selectedTab;
    } else this.contactManageService.setIsPreview(false);
  }

  ngOnDestroy(): void {
    this.contactManageService.reset();
  }
}
