import { Attachment } from '@shared/interfaces/attachment.interface';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { ICommentaryEvent } from '../../interface/commentary-event.interface';
import { CommentaryEventType } from '../../enums/commentary-event-type.enum';
import { Commentary } from '../../models/commentary.model';
import { Employee } from '@shared/models/employee.model';
import { publicFile } from '@shared/helpers/public-file.helper';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { TranslateService } from '@ngx-translate/core';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { Config } from '@shared/configs/config';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDiscardModalComponent } from '@shared/components/confirmation-discard-modal/confirmation-discard-modal.component';

@Component({
  selector: 'commentary-item-v2',
  templateUrl: './commentary-item-v2.component.html',
  styleUrls: ['./commentary-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentaryItemV2Component {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;
  Config = Config;

  attachments: Array<TextAttachment> = [];
  attachmentsToDelete: Array<number> = [];
  isEditMode = false;

  form: FormGroup = this.fb.group({
    note: [null],
    files: [null]
  });

  config: GbxsoftInputConfig = {
    name: ' ',
    type: GbxsoftInputTypes.TEXT,
    placeholder: this.t.instant('Commentaries.addCommentary'),
    showPlaceholderOnFocus: true
  };

  get formData() {
    const files = this.form.value['files']?.filter((i) => !i.created);

    let _data = {
      content: this.form.get('note').value || '',
      attachments: files ? files : [],
      attachmentsToDelete: this.attachmentsToDelete
    };

    return _data;
  }

  @ViewChild('textAttach') textAttach: TextAttachmentsInputComponent;

  @Input() maxImagesToShow: number = 3;
  @Input() commentary: Commentary = null;
  @Input() attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true
  };

  @Output() commentaryEvent: EventEmitter<ICommentaryEvent> = new EventEmitter();
  @Output() commentaryEdited: EventEmitter<any> = new EventEmitter();

  constructor(
    private changes: ChangeDetectorRef,
    private fb: FormBuilder,
    private t: TranslateService,
    private dialog: MatDialog
  ) {}

  getPhoto(employee: Employee) {
    return employee?.userPerson?.photo ? publicFile(employee.userPerson.photo) : null;
  }

  @Permission({
    group: PermissionsGroups.COMMENTARIES,
    action: 'EDIT',
    objectCreatorIdMethod: (self: CommentaryItemV2Component) => [self?.commentary?.creator?.id]
  })
  edit() {
    this.isEditMode = true;
    this.changes.detectChanges();

    setTimeout(() => {
      this.attachments = this.commentary['attachments']?.map((attachment: Attachment) =>
        this.textAttach.getAttachment(attachment as any, null, attachment)
      );
      this.form.patchValue({ note: this.commentary.content });
      this.form.markAsPristine();
      this.textAttach.setFilesToFormControl();
      this.changes.detectChanges();
    });
  }

  @Permission({
    group: PermissionsGroups.COMMENTARIES,
    action: 'REMOVE',
    objectCreatorIdMethod: (self: CommentaryItemV2Component) => [self?.commentary?.creator?.id]
  })
  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(
        () => {
          this.commentaryEvent.emit({ type: CommentaryEventType.REMOVE, data: this.commentary });
          this.changes.detectChanges();
        },
        () => {},
        {
          title: 'Commentaries.V2.removeEitle',
          body: 'Commentaries.V2.removeDescription',
          submitButtonText: 'Commentaries.V2.removeConfirm'
        }
      )
      .subscribe();
  }

  removeFileEvent(attachment: TextAttachment) {
    attachment.id ? this.attachmentsToDelete.push(parseInt(attachment.id)) : null;
    this.changes.detectChanges();
  }

  cancelEdit() {
    if (this.form.pristine) {
      this.isEditMode = false;
      this.attachments = [];
      this.attachmentsToDelete = [];
    } else {
      let dialogRef = this.dialog.open(ConfirmationDiscardModalComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        switch (confirmed) {
          case true:
            {
              this.isEditMode = false;
              this.attachments = [];
              this.attachmentsToDelete = [];
              this.changes.detectChanges();
            }
            break;
          default:
            break;
        }
      });
    }
  }

  saveEditedComment() {
    this.commentaryEdited.emit(this.formData);
    this.isEditMode = false;
    this.attachments = [];
    this.attachmentsToDelete = [];
  }

  trackAttachment(index: number, attachment: Attachment) {
    return attachment.id;
  }
}
