import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './confirmation-discard-modal.component.html'
})
export class ConfirmationDiscardModalComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(public dialogRef: MatDialogRef<ConfirmationDiscardModalComponent>) {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }
}
