import { TaskController } from '@shared/controllers/task.controller';
import { filter, delay } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { Task } from '@shared/models/task.model';
import { Subscription } from 'rxjs';
import { SubtasksManagerComponent } from '@shared/modules/subtasks/components/subtasks-manager/subtasks-manager.component';
import { CommentaryViewType } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { CommentaryType } from '@shared/modules/commentary/enums/commentary-type.enum';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TranslateService } from '@ngx-translate/core';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TaskStatusList } from '@modules/protocols/shared/consts/task-status.list';
import { AttachmentManagerListComponent } from '@shared/modules/attachment-manage/components/attachment-manager-list/attachment-manager-list.component';
import { ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { ChangeStatusList } from '@modules/protocols/shared/consts/changes-status.list';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { TaskSidenavManager } from '@shared/modules/task-sidenav/managers/task-sidenav.manager';
import { EStatusColor } from '@shared/modules/ui/components/status-cell/status-cell.component';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { CommentaryEventType } from '@shared/modules/commentary/enums/commentary-event-type.enum';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { ETaskAction } from '@shared/modules/task-sidenav/enums/task-action.enum';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';
import { Config } from '@shared/configs/config';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'task-sidenav-white-content',
  templateUrl: './task-sidenav-white-content.component.html',
  styleUrls: ['./task-sidenav-white-content.component.scss']
})
export class TaskSidenavWhiteContentComponent implements AfterViewInit, OnDestroy {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  CommentaryType = CommentaryType;
  CommentaryViewType = CommentaryViewType;
  EStatusColor = EStatusColor;
  Config = Config;

  @ViewChild('tooltipCopy') tooltip: MatTooltip;

  taskManager: TaskSidenavManager = new TaskSidenavManager();

  TaskSubscription: Subscription;
  subUpdate: Subscription = new Subscription();

  TaskStatusList: Array<any> = TaskStatusList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  ChangeStatusList: Array<any> = ChangeStatusList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  }).filter((i: any) => {
    if (i?.id === ProjectChangesStatus.CHANGE_STATUS_SETTLED && !!this.store.Employee.isClient) {
      return false;
    }
    return true;
  });

  attachmentsConfig: TextAttachmentsConfig = {
    fileAttachments: true,
    photoAttachments: true,
    videoAttachments: true,
    maxAttachmentSize: 50
  };

  get TaskTitle() {
    if (this.service?.task?.isDeleted) {
      return `Tasks.deleted-${this.service?.selectedType?.type}`;
    }

    if (this.service.isPreview || this.service.isEdit) {
      return this.service?.task?.description;
    }

    return `Projects.new${this.capitalize(this.service?.selectedType?.type)}`;
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  get ChangesTitle() {
    if (this.service?.task?.isDeleted) {
      return `Projects.deletedChange`;
    }

    if (this.service.isPreview) {
      return `Projects.previewChange`;
    }

    if (this.service.isEdit) {
      return `Projects.editChange`;
    }

    return 'Projects.newChange';
  }

  get sidenavTitle() {
    if (this.service.isTaskSelection) {
      return this.TaskTitle;
    }

    if (this.service.isConfigChange) {
      return this.ChangesTitle;
    }

    return '';
  }

  get canOpenTaskEdit() {
    return this.service.isPreview && this.service.permissionCtrl.edit;
  }

  get canRemove() {
    return this.service.isPreview && this.service.permissionCtrl.remove;
  }

  get canAddTask() {
    return !this.service.task?.isDeleted && this.service.isPreview && this.service.permissionCtrl.add;
  }

  get canShare() {
    return this.service.isPreview && this.service.permissionCtrl.share && !this.service.task?.isDeleted;
  }

  get canEditTask() {
    return this.service.isPreview && this.service.permissionCtrl.edit;
  }

  @Output('onSubmit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('attachmentsList') attachmentsList: AttachmentManagerListComponent;
  @ViewChild('subtasks') subtasks: SubtasksManagerComponent;

  constructor(
    public service: TaskSidenavService,
    public listTaskService: ListTaskService,
    private t: TranslateService,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private store: StorageService
  ) {}

  ngAfterViewInit() {
    this.service.registerAttachmentList(this.attachmentsList);
    this.subscribeSubtaskCreation();
    this.subscribeTaskUpdate();

    const sub = this.service.taskActionEmitter
      .pipe(
        filter((i) => i.action === ETaskAction.ACTION_TASK_LOADED),
        delay(10)
      )
      .subscribe(() => {
        this.ChangeStatusList = this.ChangeStatusList.map((i: any) => {
          if (i.id === ProjectChangesStatus.CHANGE_STATUS_SETTLED) {
            i.disabled = this.service.task.changeStatus === ProjectChangesStatus.CHANGE_STATUS_REJECTED;
          }
          return i;
        });
      });
    this.subUpdate.add(sub);
  }

  createTask() {
    const ctrl = new ChangesSidenavController();
    ctrl.taskCreate(this.service.task?.id, this.service.task?.project?.id);
  }

  showTooltip() {
    setTimeout(() => {
      this.tooltip.show();
      setTimeout(() => {
        this.tooltip.hide();
      }, 600);
    }, 0);
  }

  shareChange() {
    const shareData = () => {
      const ctrl = new ChangesSidenavController();
      ctrl.shareSingleChange(this.service.task, this.service.task?.project?.id);
    };

    if (this.service.task?.changeStatus === ProjectChangesStatus.CHANGE_STATUS_SETTLED) {
      const ctrl = new TaskController(this.service.task);
      ctrl.askForShare(this.service.task).then(() => {
        shareData();
      });
    } else {
      shareData();
    }
  }

  subscribeSubtaskCreation() {
    if (this.service.isCreation) {
      return;
    }
    this.TaskSubscription = this.subtasks?.service?.taskAdd.subscribe((task: Task) => {
      this.setNewSubtask(task);
      this.changes.detectChanges();
    });
  }

  subscribeTaskUpdate() {
    const sub = this.service.taskActionEmitter.subscribe(() => this.changes.detectChanges());
    this.subUpdate.add(sub);
  }

  setNewSubtask(task: Task) {
    const arr = [...(this.service.task?.children || [])];
    arr?.push(this.service.createTask(task));
    this.service.task.children = [...arr];
    this.service.task.sortAllChildren();
    this.changes.detectChanges();
  }

  filesUploaded($event) {
    const data = {
      attachmentPacketId: this.service?.attachmentsListRef?.service?.attachmentPacket?.id || null
    };

    this.service.saveForm(data).subscribe(() => {
      this.attachmentsList.service.attachmentPacket = null;
      this.changes.detectChanges();
    });
  }

  onAttachmentsChange(attachments: Attachment[]) {
    if (!this.service.task) return;
    const task = Object.assign({}, this.service.task);
    task.attachments = attachments.filter((i) => i.isUploaded);

    this.listTaskService.emitter.emit({
      type: ListTaskEventType.UPDATE_TASK_ATTACHMENTS,
      data: task
    });
  }

  changeStatusEvent(event: any) {
    const setStatusValue = () =>
      this.service.form.get(this.service.TASK_SIDENAV_FORM.changeStatus).setValue(event?.id);

    if (
      this.service?.task?.changeStatus === ProjectChangesStatus.CHANGE_STATUS_SETTLED &&
      event?.id === ProjectChangesStatus.CHANGE_STATUS_SHARED
    ) {
      const ctrl = new TaskController(this.service.task);
      ctrl
        .askForShare(this.service.task)
        .then(() => {
          setStatusValue();
        })
        .catch(() => {
          this.service.changeStatusHelper.setValue(ProjectChangesStatus.CHANGE_STATUS_SETTLED);
        });
    } else {
      setStatusValue();
    }
  }

  openCalendarTask() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent({ taskId: this.service.task.id });
  }

  deleteTask() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.service
          .deleteTask()
          .subscribe({
            next: () => this.successDeletingTask(),
            error: () => this.errorDeletingTask()
          })
          .add(() => this.changes.detectChanges());
      })
      .subscribe();
  }

  successDeletingTask() {
    this.s.success(this.t.instant(`Tasks.${this.service.task?.type}Deleted`));
    this.listTaskService.emitter.emit({
      type: ListTaskEventType.REMOVE_TASK,
      data: { task: this.service.task, closeSidenav: true }
    });
  }

  errorDeletingTask() {
    this.s.error(this.t.instant(`Tasks.errorDelete`));
  }

  updateCommentaries(e: { type: CommentaryEventType; data: any }) {
    switch (e.type) {
      case CommentaryEventType.ADD:
      case CommentaryEventType.EDIT:
      case CommentaryEventType.REMOVE:
        this.service.task.comments = e.data.list;
        break;
      default:
        break;
    }

    this.listTaskService.emitter.emit({
      type: ListTaskEventType.UPDATE_TASK,
      data: this.service.task
    });
    this.changes.detectChanges();
  }

  openTaskEdit() {
    this.taskManager.editTask(this.service?.task);
  }

  ngOnDestroy() {
    this.TaskSubscription?.unsubscribe();
    this.subUpdate?.unsubscribe();
  }
}
