<div
  class="list-tasks-single-task"
  [ngClass]="'list-tasks-single-task--' + task?.status"
  #host
  [ngClass]="{
    'list-tasks-single-task--subtask': parentTask,
    'list-tasks-single-task--opened': task?.openedSubtasks,
    'list-tasks-single-task--disabled': disabled,
    'list-tasks-single-task--my-work': listTaskService?.isMyWorkTaskLists,
    'list-tasks-single-task--active': isActive
  }"
>
  <div class="list-tasks-single-task__container">
    <div class="list-tasks-single-task__content">
      <div class="list-tasks-single-task__content__expander">
        <expander
          [opened]="task?.openedSubtasks"
          (onClick)="toggleSubtasks()"
          *ngIf="task?.childrenCount"
        ></expander>
      </div>

      <task-checkbox
        *ngIf="taskPermission?.edit"
        [task]="task"
        (onCheckSubtasks)="checkedSubtasks($event)"
        (onUpdateTask)="updateTask($event)"
        #taskCheckboxComponent
      ></task-checkbox>

      <div class="w-100" [ngClass]="'list-tasks-single-task__content--' + task?.status">
        <div class="task-name-container">
          <div class="task-name-wrapper">
            <div class="mr-2">
              <avatar-multiple
                [assignSearchDropdownTask]="task"
                [items]="[].concat(task?.assignedEmails || [], task?.assignedTo || [])"
                [width]="24"
                [disabledSearch]="!taskPermission?.edit"
                (onAssignSearchDropdownTaskChange)="onAssignSearchDropdownTaskChange($event)"
              ></avatar-multiple>
            </div>

            <a
              class="task-name"
              draggable="false"
              [href]="taskSidenavController.getPreviewTaskPath(task)"
              (click)="$event.preventDefault(); !disabled ? previewTask() : null"
              [title]="task?.taskId ? task?.taskId + ' | ' + task?.description : task?.description"
            >
              {{ task?.description }}
            </a>
          </div>

          <div class="task-info">
            <div class="list-tasks-single-task__info__deadline" *ngIf="task?.termEnd">
              <ng-container *ngIf="!task?.termStartTheSameTermEnd">
                {{ task?.termStart | dateFormat: 'DD MMM' }} - {{ task?.termEnd | dateFormat: 'DD MMM' }}
              </ng-container>

              <ng-container *ngIf="task?.termStartTheSameTermEnd">
                {{ task?.termEnd | dateFormat: 'DD MMM' }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- #region SubTasks -->
  <div
    class="list-tasks-single-task__subtasks-container"
    *ngIf="task?.openedSubtasks"
    [ngClass]="{ 'list-tasks-single-task__subtasks-container--opened': task?.openedSubtasks }"
  >
    <ng-container *ngFor="let subtask of task?.children; trackBy: identify">
      <list-tasks-single-task-v2
        *ngIf="!subtask?.deleted"
        [taskId]="subtask?.id"
        [dataFromHashmap]="true"
        [parentTask]="task"
        [parentTaskComponent]="this"
        #subtasksComponents
      ></list-tasks-single-task-v2>
    </ng-container>
  </div>
  <!-- #endregion -->
</div>
