import { EventSidenavComponent } from './components/event-sidenav/event-sidenav.component';
import { EventDetailsComponent } from './components/event-sidenav/components/event-details/event-details.component';
import { EventCreationComponent } from './components/event-sidenav/components/event-creation/event-creation.component';
import { EventDiscardDialogComponent } from './components/event-sidenav/components/event-discard-dialog/event-discard-dialog.component';

import { EventSidenavService } from './services/event-sidenav.service';
import { EventParticipantsListComponent } from './components/event-sidenav/components/event-details/components/event-participants-list/event-participants-list.component';
import { EventProtocolsListComponent } from './components/event-sidenav/components/event-details/components/event-protocols-list/event-protocols-list.component';
import { EventDataComponent } from './components/event-sidenav/components/event-details/components/event-data/event-data.component';
import { EventStickyActionComponent } from './components/event-sidenav/components/event-sticky-action/event-sticky-action.component';
import { EventEditComponent } from './components/event-sidenav/components/event-edit/event-edit.component';
import { EventProtocolSingleComponent } from './components/event-sidenav/components/event-details/components/event-protocols-list/components/event-protocol-single/event-protocol-single.component';
import { ParticipantStatusComponent } from './components/event-sidenav/components/event-details/components/participant-status/participant-status.component';

export const EVENT_SIDENAV_COMPONENTS = [
  EventSidenavComponent,
  EventDetailsComponent,
  EventCreationComponent,
  EventDiscardDialogComponent,
  EventParticipantsListComponent,
  EventProtocolsListComponent,
  EventDataComponent,
  EventStickyActionComponent,
  EventEditComponent,
  EventProtocolSingleComponent,
  ParticipantStatusComponent
];

export const EVENT_SIDENAV_COMPONENTS_EXPORT = [EventSidenavComponent];
export const EVENT_SIDENAV_SERVICES = [EventSidenavService];
