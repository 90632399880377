<modal-title
  class="modal-title-v2"
  [title]="'Settings.Team.editEmployeeTitle' | translate"
  [closeCallback]="cancel.bind(this)"
></modal-title>
<!-- #region Content -->
<form [formGroup]="form">
  <mat-dialog-content class="p-1">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <div class="new-select-label">{{ 'Settings.Team.employeePosition' | translate }}</div>
          <ng-select
            formControlName="role"
            [items]="positionSelectOptions"
            appendTo="body"
            bindLabel="name"
            bindValue="value"
            [clearable]="false"
            [placeholder]="'Settings.Team.employeePosition' | translate"
            class="new-select"
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <div class="new-select-label">{{ 'Settings.Team.hourlyRate' | translate }}</div>
          <input-v2
            class="text-control required"
            formControlName="hourlyRate"
            [errMessages]="errorMessages('hourlyRate')"
            [placeholder]="'Settings.Team.hourlyRate' | translate"
            [type]="'number'"
          >
          </input-v2>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <div class="d-flex mb-4">
    <app-button
      class="mr-2"
      [disabled]="loading"
      (onClick)="cancel()"
      [config]="{
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        text: 'Settings.Team.cancel' | translate,
        size: ButtonSize.LARGE
      }"
    ></app-button>
    <app-button
      class=""
      (onClick)="submit()"
      [loading]="loading"
      [disabled]="loading"
      [config]="{
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text: 'Settings.Team.save' | translate,
        size: ButtonSize.LARGE
      }"
    ></app-button>
  </div>
</mat-dialog-actions>
<!-- #endregion -->
