import { Component, Input } from '@angular/core';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { ListService } from '@shared/modules/list/services/list.service';

@Component({
  selector: 'list-chips',
  templateUrl: './list-chips.component.html',
  styleUrls: ['./list-chips.component.scss']
})
export class ListChipsComponent {
  @Input() isShowCompletedFunctionality = false;

  constructor(public service: ListService) {}

  remove(chip: any, value: any, multi: boolean = false) {
    //! Handler for removing "show_completed" when we delete COMPLETED status
    if (this.isShowCompletedFunctionality && value === TaskStatus.COMPLETED) {
      this.service.setFilter('[show_completed]', 0);
    }

    if (multi) {
      this.service.removeFilterByKeyAndValue(chip.key, value);
    } else {
      this.service.removeFilterByKey(chip.key);
    }
  }

  get isSomeSelected(): boolean {
    return Object.values(this.service.chips).some((v) => ((v as any) || []).length);
  }
}
