<div class="task__sidenav__white">
  <!-- #region Content -->
  <div class="task__sidenav__white__content">
    <!-- #region Create State -->
    <task-sidenav-white-content
      (onSubmit)="submitForm()"
      [hidden]="
        !((service.isPreview && !!service.task) || service.isCreation || (service.isEdit && !!service.task))
      "
    ></task-sidenav-white-content>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <!-- #region Footer -->
  <div
    class="sticky-footer d-flex justify-content-between"
    *ngIf="service.isCreation || (service.isEdit && !!service.task)"
  >
    <app-button
      (onClick)="service.discardSaving()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_SECONDARY_BLUE_BACKGROUND,
        text: 'Projects.discard' | translate
      }"
    ></app-button>

    <app-button
      (onClick)="submitForm()"
      [disabled]="service.loading || !!service?.task?.isDeleted"
      [loading]="service.loading"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text: 'Projects.saveAndEnd' | translate
      }"
    ></app-button>
  </div>
  <!-- #endregion -->
</div>
