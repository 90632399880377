<div class="assign-search-dropdown">
  <div class="assign-search-dropdown__search">
    <form [formGroup]="formGroup">
      <gbxsoft-input
        #searchInput
        class="special-control search-control"
        formControlName="search"
        (keydown)="searchEmployee()"
        [config]="{ name: 'Global.search' | translate, type: 'text', placeholder: '' }"
      >
      </gbxsoft-input>
    </form>
  </div>
  <div class="assign-search-dropdown__list">
    <div class="assign-search-dropdown__list__loader" *ngIf="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
    <ng-container *ngIf="assignSearchDropdown?.employees.length">
      <h5 class="assign-search-dropdown__list__title">
        {{ 'Projects.team' | translate }}
      </h5>

      <div
        class="assign-search-dropdown__list__item"
        *ngFor="let employee of assignSearchDropdown.employees"
        (click)="$event.stopPropagation(); assignEmployeeToTask(employee)"
      >
        <avatar [imageUrl]="imageUrl(employee?.userPerson?.photo)"></avatar>
        <div class="ml-2">
          <h5 class="assign-search-dropdown__list__item__name">
            {{ employee.userPerson.previewName }}
          </h5>
          <span class="assign-search-dropdown__list__item__position">
            {{ 'ROLES.' + employee.role | translate }}
          </span>
        </div>
      </div>
    </ng-container>

    <hr *ngIf="assignSearchDropdown?.contacts.length && assignSearchDropdown?.employees.length" />

    <ng-container *ngIf="assignSearchDropdown?.contacts.length">
      <h5 class="assign-search-dropdown__list__title">
        {{ 'Projects.contacts' | translate }}
      </h5>

      <div
        class="assign-search-dropdown__list__item"
        *ngFor="let contact of assignSearchDropdown.contacts"
        (click)="$event.stopPropagation(); assignContactToTask(contact)"
      >
        <avatar></avatar>
        <div class="ml-2">
          <h5 class="assign-search-dropdown__list__item__name">
            {{ contact?.fullName || contact?.email }}
          </h5>
          <span class="assign-search-dropdown__list__item__position" *ngIf="contact.employee">
            {{ 'ROLES.' + contact.employee.role | translate }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
