import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

export interface ImageCropperModalData {
  image: Event;
}

@Component({
  templateUrl: './image-cropper-modal.component.html',
  styles: [
    `
      ::ng-deep .modal-title {
        border-bottom: 0 !important;
      }
    `
  ]
})
export class ImageCropperModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  croppedImage: string;
  imageChangedEvent: Event;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperModalData
  ) {}

  ngOnInit(): void {
    this.imageChangedEvent = this.data.image;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  loadImageFailed(): void {
    this.snackBar.error(this.translate.instant('imageLoadError'));
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const file = base64ToFile(this.croppedImage);
    this.dialogRef.close(file ?? false);
  }
}
