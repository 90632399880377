<div class="task-basic-preview-data">
  <div class="d-flex flex-column">
    <div
      *ngIf="!!service.task?.parent?.description"
      class="pointer parent-link"
      (click)="openParentPreview(service.task?.parent)"
    >
      <svg-icon [src]="'/assets/img/arrow-left-blue.svg'"></svg-icon>
      <span [title]="service.task?.parent?.description">
        {{ service.task?.parent?.description }}
      </span>
    </div>
    <div class="d-flex flex-row mb-3 align-items-center">
      <div *ngIf="!service.isConfigChange" class="mr-1">
        <task-checkbox
          *ngIf="taskPermission?.edit"
          [task]="service?.task"
          [placement]="'bottom'"
          (onCheckSubtasks)="checkedSubtasks($event)"
          (onUpdateTask)="updateTaskFromCheckbox($event)"
          #taskCheckboxComponent
        ></task-checkbox>
      </div>
      <div>
        <div class="task-name">{{ service?.task?.description }}</div>
        <div
          *ngIf="shouldShowProjectInfo"
          class="pointer"
          (click)="navigateToProject(service?.task?.project)"
        >
          <span *ngIf="assignedDisplayName" class="client-name"> {{ assignedDisplayName }}&nbsp; </span>
          <span class="address">{{ service?.task?.project?.fullAddress }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!!service.safeContent" class="mb-4 value ql-editor" [innerHTML]="service.safeContent"></div>
  </div>

  <ng-container *ngIf="service.isConfigChange && service.isPreview">
    <task-changes-summary></task-changes-summary>
  </ng-container>

  <div
    *ngIf="!!service.isConfigChange && !storage?.Employee?.isClient && service.safeContentInternalNote"
    class="mb-4"
  >
    <div class="new-select-label red">{{ 'Projects.internalNote' | translate }}</div>
    <div
      *ngIf="!!service.safeContentInternalNote"
      class="value ql-editor"
      [innerHTML]="service.safeContentInternalNote"
    ></div>
  </div>

  <div class="mb-4">
    <task-sidenav-blue></task-sidenav-blue>
  </div>
</div>
