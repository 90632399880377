<div class="new-modal">
  <div class="title__wrapper">
    <span class="title">{{ modalTitle | translate }}</span>
    <svg-icon class="clear-icon" src="/assets/img/close-icon.svg" (click)="cancel()"></svg-icon>
  </div>

  <mat-dialog-content>
    <contact-exists-info
      [form]="form"
      [isInited]="!data?.contact?.id"
      #contactExistsInfoComponent
    ></contact-exists-info>

    <form [formGroup]="form" class="rows__wrapper">
      <ng-select
        ngSelectExtension
        class="new-select"
        bindValue="id"
        [clearable]="false"
        [searchable]="false"
        [items]="contactTypeList"
        [notFoundText]="'Contacts.V2.notFoundText' | translate"
        [tabIndex]="-1"
        [formControlName]="NEW_CONTACT_FORM.type"
      ></ng-select>

      <div *ngIf="isCompany" class="row">
        <div class="col-12">
          <input-label [label]="'Contacts.V2.companyName' | translate" [required]="true"></input-label>
          <input-v2
            [formControlName]="NEW_CONTACT_FORM.companyName"
            [placeholder]="'Contacts.V2.companyNamePlaceholder' | translate"
          ></input-v2>
        </div>
      </div>

      <div *ngIf="!isCompany" class="row">
        <div class="col-6 control-v2">
          <input-label [label]="'Contacts.V2.firstName' | translate" [required]="true"></input-label>
          <input-v2
            [formControlName]="NEW_CONTACT_FORM.firstName"
            [placeholder]="'Contacts.V2.firstNamePlaceholder' | translate"
          ></input-v2>
        </div>
        <div class="col-6 control-v2">
          <input-label [label]="'Contacts.V2.lastName' | translate" [required]="true"></input-label>
          <input-v2
            [formControlName]="NEW_CONTACT_FORM.lastName"
            [placeholder]="'Contacts.V2.lastNamePlaceholder' | translate"
          ></input-v2>
        </div>
      </div>

      <div class="control-v2">
        <ng-select
          ngSelectExtension
          class="new-select"
          [url]="CONFIG.API + '/tag/type?type=acquisition-source'"
          [placeholder]="'Contacts.Fields.contactSource' | translate"
          [notFoundText]="'Contacts.Manage.notFoundSource' | translate"
          [addTagText]="'Contacts.Manage.addContactSource' | translate"
          [formControlName]="NEW_CONTACT_FORM.acquisitionSource"
          [bindLabel]="'name'"
          [bindValue]="'name'"
          [addTag]="true"
          [tabIndex]="-1"
          [translateLabelPrefix]="'Contacts.Source.'"
          [inputAttrs]="{ maxlength: '255' }"
        ></ng-select>
      </div>

      <div class="control-v2">
        <input-label [label]="'Contacts.V2.availability' | translate"></input-label>
        <input-v2
          [formControlName]="NEW_CONTACT_FORM.availability"
          [placeholder]="'Contacts.V2.availabilityPlaceholder' | translate"
        ></input-v2>
      </div>

      <div class="control-v2">
        <input-label [label]="'Contacts.V2.phone' | translate"></input-label>
        <phone-input-v2
          [form]="form"
          [phoneFlagName]="NEW_CONTACT_FORM.phoneCountry"
          [phoneName]="NEW_CONTACT_FORM.phone"
          [inputPlaceholder]="'Contacts.V2.phonePlaceholder' | translate"
        ></phone-input-v2>
      </div>

      <div class="control-v2">
        <input-label [label]="'Contacts.V2.email' | translate"></input-label>
        <input-v2
          [formControlName]="NEW_CONTACT_FORM.email"
          [placeholder]="'Contacts.V2.emailPlaceholder' | translate"
        ></input-v2>
      </div>

      <div class="control-v2">
        <ng-select
          ngSelectExtension
          class="new-select new-select-multiple"
          dropdownPosition="top"
          bindLabel="name"
          [url]="CONFIG.API + '/tag/type?type=contact-function'"
          [formControlName]="NEW_CONTACT_FORM.functions"
          [placeholder]="'Contacts.Manage.contactFunction' | translate"
          [notFoundText]="'Contacts.Manage.notFoundFunction' | translate"
          [multiple]="true"
          [tabIndex]="-1"
          [closeOnSelect]="false"
          [inputAttrs]="{ maxlength: '255' }"
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$">
            <svg-icon *ngIf="item$.selected" src="/assets/img/check-checked-icon.svg"></svg-icon>
            <div *ngIf="!item$.selected" class="not-selected"></div>
            <span>{{ item.internal ? ('Contacts.Function.' + item.name | translate) : item.name }}</span>
          </ng-template>
          <ng-template ng-multi-label-tmp>
            <div class="selected-items text-capitalize">
              <ng-container *ngIf="selectedFunctions.length === 1">
                <span *ngFor="let selectedFunction of selectedFunctions" class="selected-item">
                  {{
                    selectedFunction.internal
                      ? ('Contacts.Function.' + selectedFunction.name | translate)
                      : selectedFunction.name
                  }}
                </span>
              </ng-container>
              <span class="selected-number" *ngIf="selectedFunctions.length > 1">
                {{ 'Global.selected' | translate: { count: selectedFunctions.length } }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </form>
  </mat-dialog-content>

  <div class="d-flex justify-content-end mt-4">
    <app-button
      (onClick)="cancel()"
      [config]="{
        text: 'Contacts.Preview.cancel' | translate,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      [class]="'ml-2'"
      [disabled]="loading"
      [loading]="loading"
      (onClick)="submitContact()"
      [config]="{
        text: 'Contacts.Preview.saveContactPerson' | translate,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>
  </div>
</div>
