<div
  class="d-flex contact__task__sidenav"
  [ngClass]="{ 'contact__task__sidenav--full-width': WindowHelper.isLessMD }"
>
  <sidenav-loading *ngIf="showLoading" [cover]="true"></sidenav-loading>

  <contact-manage-panel
    *ngIf="showContactPanel"
    [contact]="contact"
    [selectedTab]="selectedTab"
  ></contact-manage-panel>
</div>
