<form [formGroup]="service.form">
  <div class="row mb-4">
    <div class="new-select-label col-12">
      {{ 'Protocols.describeFinancialChange' | translate }}
    </div>
    <div class="row col-12">
      <div class="value col-6">
        <ng-container *ngIf="service.task?.price === 0">
          {{ 'Financial.withoutInfluence' | translate }}
        </ng-container>

        <ng-container *ngIf="service.task?.price > 0">
          {{ 'Financial.additionalCost' | translate }}
        </ng-container>

        <ng-container *ngIf="service.task?.price < 0">
          {{ 'Financial.savedCost' | translate }}
        </ng-container>
      </div>
      <div class="value col-6">
        <span *ngIf="!service.task?.isPriceEstimated && service?.task?.price === 0">{{
          'Financial.withoutEstimation' | translate
        }}</span>
        <span *ngIf="!(!service.task?.isPriceEstimated && service?.task?.price === 0)">{{
          service.task?.price
            | changeValue: (service.task?.price | priceFormat: false : store?.Company?.currency)
        }}</span>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="new-select-label col-12">
      {{ 'Protocols.additionalSavedTime' | translate }}
    </div>
    <div class="row col-12">
      <div class="value col-6">
        {{ 'ProjectChanges.additionalDays' | translate }}
      </div>
      <div class="value col-6">
        <span
          *ngIf="
            !service.task?.isAdditionalTimeEstimated &&
            service.task?.additionalTime?.toString() === 0?.toString()
          "
        >
          {{ 'Financial.withoutEstimation' | translate }}</span
        >
        <span
          *ngIf="
            !(
              !service.task?.isAdditionalTimeEstimated &&
              service.task?.additionalTime?.toString() === 0?.toString()
            )
          "
          >{{ service.task?.additionalTime | changeValue: service.task?.additionalTime : true }}
          {{ 'Protocols.days' | translate }}</span
        >
      </div>
    </div>
  </div>

  <div
    [hidden]="!service.isConfigChange"
    *ngIf="
      !(
        service?.storage?.Employee?.isClient ||
        service?.storage?.Employee?.isWorker ||
        service?.storage?.Employee?.isExternalUser
      ) && service?.isChange;
      else readStatus
    "
    class="row mb-4"
  >
    <div class="col-12 new-select-label">{{ 'ProjectChanges.List.settlementStatus' | translate }}</div>
    <div class="col-12 col-sm-6">
      <ng-select
        [items]="settlementsItems"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [clearable]="false"
        [searchable]="false"
        [formControlName]="service.TASK_SIDENAV_FORM.settlementStatus"
        [tabIndex]="-1"
        [notFoundText]="'Projects.notFoundText' | translate"
        class="new-select"
        [readonly]="
          service.task?.isDeleted || (!service.permissionCtrl.edit && (service.isEdit || service.isPreview))
        "
      ></ng-select>
    </div>
  </div>

  <ng-template #readStatus>
    <div class="new-select-label col-12">
      {{ 'Protocols.billingStatus' | translate }}
    </div>
    <div class="row col-12">
      <div class="value col-6">
        {{ 'Projects.ProjectSettlement.' + service.task?.settlementStatus | translate }}
      </div>
      <div class="value col-6"></div>
    </div>
  </ng-template>
</form>
