import { ITransformedFile } from './../../shared/interfaces/transformed-file.interface';
import { interval } from 'rxjs';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { AttachmentType } from '@shared/components/text-attachments-input/enums/text-attachment-type.enum';
import {
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  AfterViewInit,
  Component
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { GbxsoftInputComponent } from '@form/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import * as moment from 'moment';

@Component({ template: '' })
export class BaseAttachmentComponent implements AfterViewInit {
  @ViewChild('input') input: GbxsoftInputComponent;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('textAttachmentControl') textAttachmentControl: ElementRef;

  @Input() form: FormGroup;
  @Input() textName?: string;
  @Input() fileName?: string;
  @Input() config: GbxsoftInputConfig;
  @Input() disabled?: boolean = false;
  @Input() onlyTextInteraction?: boolean = false;
  @Input() attachments?: TextAttachment[] = [];
  @Input() attachmentsConfig: TextAttachmentsConfig;

  @Input() inputTour: string;
  @Input() buttonTour: string;

  @Output() uploadFile: EventEmitter<{ file: TextAttachment; callback?: Function }> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<TextAttachment> = new EventEmitter();

  AttachmentType = AttachmentType;

  fileLoading: boolean = false;

  ButtonTypes = ButtonTypes;

  loadedAttachments: any[] = [];

  // validFiles = 0;

  constructor(
    public translate: TranslateService,
    public changes: ChangeDetectorRef,
    public s: SnackBarService,
    public authFilePipe: AuthFilePipe
  ) {}

  ngAfterViewInit() {
    this.changes.detectChanges();
  }

  get isDoubleAttachments() {
    return this.attachmentsConfig.photoAttachments && this.attachmentsConfig.fileAttachments;
  }

  get buttonText() {
    if (this.isDoubleAttachments) {
      return this.translate.instant('Global.doubleTextAttachmentsButton');
    } else if (this.attachmentsConfig.fileAttachments) {
      return this.translate.instant('Global.fileTextAttachmentsButton');
    } else if (this.attachmentsConfig.videoAttachments) {
      return this.translate.instant('Global.videoTextAttachmentsButton');
    } else {
      return this.translate.instant('Global.photoTextAttachmentsButton');
    }
  }

  get imageSrc() {
    if (!!this.attachmentsConfig.photoAttachments && !this.attachmentsConfig.fileAttachments) {
      return 'ic-image.svg';
    }
    return 'ic-file-plus.svg';
  }

  get checkAttachmentLengthValid() {
    return (
      this.attachments.length < this.attachmentsConfig?.maxAttachments ||
      !this.attachmentsConfig?.maxAttachments
    );
  }

  removeItem(item: TextAttachment) {
    const index = this.attachments.findIndex((x) => x.id === item.id);
    !!this.attachments[index] ? this.removeFileEvent.emit(this.attachments[index]) : null;
    this.attachments.splice(index, 1);
    this.setFilesToFormControl();
    this.changes.detectChanges();
    this.markAsNotPristine();
  }

  getFilesArray(files?: FileList): File[] {
    const filesArr: File[] = [];
    const filesList = files ? files : this.fileInput.nativeElement.files;

    // tslint:disable-next-line: forin
    for (const index in filesList) {
      const file = filesList[index];
      if (!(file instanceof File)) {
        continue;
      }
      filesArr.push(file);
    }
    return filesArr;
  }

  isImage(file: File) {
    return file.type.indexOf('image') > -1;
  }

  isRequiredExt(file: File) {
    let split = file.name.split('.');
    const ext = split[split.length - 1];
    const extList = this.attachmentsConfig.requiredExtensions.map((i) => i.toLowerCase());
    return extList.indexOf(ext.toLowerCase()) > -1;
  }

  checkFileType(file: File) {
    if (!!this.attachmentsConfig?.requiredExtensions?.length) {
      return this.isRequiredExt(file);
    }

    if (this.attachmentsConfig?.photoAttachments && !this.attachmentsConfig?.fileAttachments) {
      return this.isImage(file);
    }

    if (!this.attachmentsConfig?.photoAttachments && this.attachmentsConfig?.fileAttachments) {
      return !this.isImage(file);
    }

    return true;
  }

  checkFileSize(file: File) {
    if (file.type.indexOf('image') > -1) {
      if (this.attachmentsConfig?.maxAttachmentImageSize) {
        return file.size / Config.B_TO_MB <= this.attachmentsConfig?.maxAttachmentImageSize;
      }
    } else {
      if (this.attachmentsConfig?.maxAttachmentFileSize) {
        return file.size / Config.B_TO_MB <= this.attachmentsConfig?.maxAttachmentFileSize;
      }
    }
    return (
      !this.attachmentsConfig?.maxAttachmentSize ||
      file.size / Config.B_TO_MB <= this.attachmentsConfig?.maxAttachmentSize
    );
  }

  filterFileBySizeType(files: File[]): ITransformedFile[] {
    return files
      .map((file: File, index: number) => this.getTransformedFile(file))
      .filter((file) => {
        const isValid = this.isFileValidSizeAndType(file);
        if (!isValid) {
          this.validateSizeOnFileChange(file.file, file.validateSize);
          this.validateTypeOnFileChange(file.file, file.validateType);
        }
        return isValid;
      });
  }

  filterFileByImageResolution(i: ITransformedFile) {
    return new Promise((resolve) => {
      if (this.isImage(i.file)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img: any = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const isValid = this.isValidResolution(img.width, img.height);
            if (isValid) {
              resolve(i);
            } else {
              this.s.error(this.translate.instant('FormErrors.maxPixels'));
              resolve(null);
            }
          };
        };
        reader.readAsDataURL(i.file);
      } else {
        resolve(i);
      }
    });
  }

  async asyncFilter(arr, predicate) {
    const results = await Promise.all(arr.map(predicate));
    return arr.filter((_v, index) => results[index]);
  }

  async onFilesChange(_files?: FileList | any, callback?: Function) {
    const files = this.getFilesArray(_files);
    this.loadedAttachments = [];

    if (files.length > 0) {
      this.fileLoading = true;
      this.changes.detectChanges();

      let transformedFiles: ITransformedFile[] = [];

      //1. Get valid files
      //2. Validate by type and delete if wrong
      transformedFiles = this.filterFileBySizeType(files);

      //3. Filter image resolutions
      transformedFiles = await this.asyncFilter(transformedFiles, async (i) => {
        return await this.filterFileByImageResolution(i);
      });

      // const fileList = transformedFiles.map((i) => i.file);

      //4. Upload Locally valid files and show errors on invalid
      transformedFiles.forEach((data: ITransformedFile, index: number) =>
        this.transformUploadedFile(data, index, callback)
      );

      //5. Wait till all files will be uploaded
      const interval$ = interval(200).subscribe(() => {
        const filteredFiles = this.loadedAttachments.filter((i) => !!i.loaded);
        if (transformedFiles.length === filteredFiles.length) {
          this.fileInput.nativeElement.value = null;
          this.onLoadingFilesEnd();
          interval$.unsubscribe();
        }
      });
    }
    this.markAsTouched();
    this.markAsNotPristine();
  }

  getTransformedFile(_file: File): ITransformedFile {
    return {
      file: _file,
      validateSize: this.checkFileSize(_file),
      validateType: this.checkFileType(_file),
      attachment: null,
      loaded: false
    };
  }

  transformUploadedFile(file: ITransformedFile, index: number, callback?: Function) {
    const reader = new FileReader();
    reader.onload = (e) => {
      file.loaded = true;
      file.attachment = this.getAttachment(file.file, e);
      this.loadedAttachments.push(file);
      this.uploadFile.emit({ file: file.attachment, callback });
      this.checkAttachmentLengthValid ? this.attachments.push(file.attachment) : null;
      this.changes.detectChanges();
    };
    reader.readAsDataURL(file.file);
  }

  isValidResolution(width: number, height: number) {
    return !(width > 10240 || height > 10240);
  }

  isFileValidSizeAndType(file: any) {
    return file.validateSize && file.validateType;
  }

  markAsTouched() {
    const control = this.form?.get(this.fileName);
    control?.markAsTouched();
    control?.updateValueAndValidity({ emitEvent: false });
  }

  markAsNotPristine() {
    const control = this.form?.get(this.fileName);
    control?.markAsDirty();
    control?.updateValueAndValidity({ emitEvent: false });
  }

  validateSizeOnFileChange(file: File, validateSize: boolean) {
    if (!validateSize) {
      let msg = this.translate.instant('FormErrors.maxFileSize').replace('{{name}}', file.name);
      let size = this.attachmentsConfig.maxAttachmentSize;

      if (file.type.indexOf('image') > -1) {
        this.attachmentsConfig.maxAttachmentImageSize
          ? (size = this.attachmentsConfig.maxAttachmentImageSize)
          : null;
      } else {
        this.attachmentsConfig.maxAttachmentFileSize
          ? (size = this.attachmentsConfig.maxAttachmentFileSize)
          : null;
      }

      msg = msg.replace('{{size}}', size);
      this.s.error(msg);
    }
  }

  validateTypeOnFileChange(file: File, validateType: boolean) {
    if (!validateType) {
      let msg = '';
      if (this.attachmentsConfig.photoAttachments && !this.attachmentsConfig.fileAttachments) {
        msg = this.translate.instant('FormErrors.onlyPhotos');
      }
      if (!this.attachmentsConfig.photoAttachments && this.attachmentsConfig.fileAttachments) {
        msg = this.translate.instant('FormErrors.onlyPhotosDeny');
      }

      if (this.attachmentsConfig.videoAttachments) {
        msg = this.translate.instant('FormErrors.onlyVideos');
      }
      this.s.error(msg);
    }
  }

  applyAttachment(files: File[], index: number) {
    this.fileInput.nativeElement.value = null;
    if (index >= files.length - 1 || index >= this.attachmentsConfig?.maxAttachments - 1) {
      this.onLoadingFilesEnd();
    }
  }

  removeAllAttachments() {
    this.attachments = [];
    this.fileInput.nativeElement.value = null;
    this.changes.detectChanges();
  }

  onLoadingFilesEnd() {
    this.setFilesToFormControl();
    this.fileLoading = false;
    this.changes.detectChanges();
  }

  setFilesToFormControl() {
    this.form.get(this.fileName) ? this.form.get(this.fileName).setValue(null) : null;
    if (this.attachmentsConfig?.maxAttachments === 1) {
      this.attachments[0] ? this.form.get(this.fileName).setValue(this.attachments[0].file) : null;
    } else {
      const files = this.attachments.map((textAttachment: TextAttachment) => textAttachment.file);
      this.form.get(this.fileName) ? this.form.get(this.fileName).setValue(files) : null;
    }
  }

  getAttachment(file: File, e, attachment?: Attachment) {
    return new TextAttachment({
      type: this.getFileType(file),
      content: e?.target?.result,
      url: attachment?.file ? this.authFilePipe.transform(attachment.file) : '',
      name: file.name,
      id: attachment?.id ? attachment?.id : this.attachmentId,
      file,
      isUploaded: !!attachment?.id,
      created: moment().format(Config.DATE_SERVER)
    });
  }

  get attachmentId() {
    return Math.floor(new Date().valueOf() * Math.random());
  }

  getFileType(file: File) {
    if (file.type.indexOf('image') !== -1) {
      return AttachmentType.PHOTO;
    } else {
      return AttachmentType.FILE;
    }
  }

  addFile() {
    if (this.fileLoading || this.attachments?.length >= this.attachmentsConfig?.maxAttachments) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  acceptFiles() {
    if (this.isDoubleAttachments || this.attachmentsConfig?.videoAttachments) {
      return '*';
    } else if (this.attachmentsConfig?.fileAttachments) {
      return Config.FILES_WITHOUT_IMAGE_ACCEPT;
    } else {
      return 'image/*';
    }
  }
}
