import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { MailingQuillFormats } from '@modules/mailing-templates/shared/modules/mailing-sidenav/components/mailing-sidenav/data/mailing-quill-formats.data';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseQuillModules } from '@shared/consts/base-quill-modules';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { HttpError } from '@shared/interfaces/error.interface';
import { UserPerson } from '@shared/interfaces/user.interface';
import { UserMailMethod } from '@shared/models/base-user-person.model';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService
} from '@shared/services/sticky-footer.service';
import { Subscription } from 'rxjs';
import { finalize, switchMap, map, tap } from 'rxjs/operators';

enum EmailSettingsForm {
  mailMethod = 'mailMethod',
  useSystemMailFooter = 'useSystemMailFooter',
  mailFooter = 'mailFooter'
}

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  modules = BaseQuillModules;
  formats = MailingQuillFormats;
  UserMailMethod = UserMailMethod;
  EmailSettingsForm = EmailSettingsForm;
  sub: Subscription = new Subscription();

  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private store: StorageService,
    private settings: SettingsService,
    private s: SnackBarService,
    private footerService: StickyFooterService,
    private t: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.subscribeTypeChange();
    this.subscribeSaving();
    this.subscribeSystemCheckboxChange();

    this.form.valueChanges
      .pipe(
        map(() => this.form.dirty),
        tap((value: boolean) => {
          this.settings.changeFormDirty(value);
        })
      )
      .subscribe();
  }

  createForm() {
    this.form = this.fb.group({
      [EmailSettingsForm.mailMethod]: [this.store.UserPerson.mailMethod],
      [EmailSettingsForm.useSystemMailFooter]: [this.store.UserPerson.useSystemMailFooter],
      [EmailSettingsForm.mailFooter]: [this.store.UserPerson.mailFooter]
    });
  }

  subscribeSystemCheckboxChange() {
    const sub = this.form
      .get(EmailSettingsForm.useSystemMailFooter)
      .valueChanges.subscribe((value: boolean) => {
        if (value) {
          this.form.get(EmailSettingsForm.mailFooter).setValue(null);
        }
      });
    this.sub.add(sub);
  }

  subscribeTypeChange() {
    const sub = this.form.get(EmailSettingsForm.mailMethod).valueChanges.subscribe({
      next: (value: UserMailMethod) => {
        this.form.get(EmailSettingsForm.mailMethod).disable({ emitEvent: false });
        this.settings
          .setDefaultMethod(value)
          .pipe(finalize(() => this.form.get(EmailSettingsForm.mailMethod).enable({ emitEvent: false })))
          .subscribe({
            next: () => {
              this.s.success(this.t.instant('Settings.Email.typeSelectedSuccess'));
            },
            error: (err: HttpError) => {
              let msg = 'Settings.Email.typeSelectedError';

              switch (err.messageCode) {
                case IntilioCodes.GOOGLE_AUTH_EXPIRED:
                  msg = 'Settings.Email.errorGoogleAuth';
                  this.s.warn(this.t.instant(msg));
                  break;
                default:
                  this.s.error(this.t.instant(msg));
                  break;
              }
            }
          });
      }
    });
    this.sub.add(sub);
  }

  subscribeSaving() {
    const sub = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submit();
          break;
        case StickyFooterEventType.DISCARD:
          this.resetForm(false);
          break;
        default:
          break;
      }
    });

    this.sub.add(sub);
  }

  resetForm(emitEvent = true) {
    this.form.patchValue(this.store.UserPerson, { emitEvent });
    this.settings.changeFormDirty(false);
  }

  submit() {
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    const { useSystemMailFooter, mailFooter } = Object.assign({}, this.form.value);

    this.settings
      .updateMailingFooter({ useSystemMailFooter, mailFooter })
      .pipe(
        switchMap(() =>
          this.settings.getUserData().pipe(map((user: UserPerson) => this.onSuccessUserData(user)))
        )
      )
      .pipe(
        finalize(() => {
          this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        })
      )
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Settings.Email.formSaveSuccess'));
        },
        error: () => {
          this.s.error(this.t.instant('Settings.Email.formSaveError'));
        }
      });
  }

  onSuccessUserData(user: UserPerson) {
    this.store.UserPerson = user;
  }

  getAccessGoogleAccount() {
    this.settings.getAccessGoogleAccount().subscribe({
      next: ({ url }) => {
        window.location.href = url;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onEditorFocus(): void {
    document.getElementById('quill-editor').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
