import { publicFile } from '@shared/helpers/public-file.helper';
import { Employee } from '@shared/models/employee.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BaseAvatarComponent } from '@shared/modules/ui/components/base-avatar.component';
import { Task } from '@shared/models/task.model';
import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';

@Component({
  selector: 'avatar-multiple',
  templateUrl: './avatar-multiple.component.html',
  styleUrls: ['./avatar-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarMultipleComponent extends BaseAvatarComponent {
  private _items: string[] | Employee[] = [];

  @Input()
  set items(items) {
    this._items = items;
  }

  get items() {
    return this._items;
  }

  @Input() assignSearchDropdownTask: Task;
  @Input() disabledSearch: boolean;
  @Input() permissionDecorator: PermissionDecorator;

  @Output('onAssignSearchDropdownTaskChange')
  onAssignSearchDropdownTaskChange: EventEmitter<Task> = new EventEmitter<Task>();

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  url(item: string | Employee) {
    if (this.disabledSearch) {
      return '/assets/img/avatar-placeholder.svg';
    }

    if (typeof item === 'object' && item.userPerson) {
      return publicFile(item.userPerson.photo, 100);
    }

    return item ? null : '/assets/img/assigne-plus.svg'; //if assigned to email then return null to show placeholder another show assignee plus icon
  }

  onTaskUpdate(task: Task) {
    this.assignSearchDropdownTask = new Task(task);
    this.items = [].concat(
      this.assignSearchDropdownTask?.assignedEmails || [],
      this.assignSearchDropdownTask?.assignedTo || []
    );
    this.onAssignSearchDropdownTaskChange.emit(this.assignSearchDropdownTask);
    this.changes.detectChanges();
  }
}
