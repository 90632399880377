<modal-title [title]="'cropImage' | translate"></modal-title>

<image-cropper
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true"
  [aspectRatio]="1 / 1"
  format="png"
  (imageCropped)="imageCropped($event)"
  (loadImageFailed)="loadImageFailed()"
></image-cropper>

<mat-dialog-actions class="mt-3 mb-0 mx-0 p-0" align="end">
  <app-button
    [class]="'mr-2'"
    (onClick)="onCancel()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
      text: 'cancel' | translate
    }"
  ></app-button>
  <app-button
    (onClick)="onSubmit()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_PRIMARY_BLUE,
      text: 'confirm' | translate
    }"
  ></app-button>
</mat-dialog-actions>
