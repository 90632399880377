<div [formGroup]="parentForm" class="card-v2 card-v2-highlighted">
  <div class="card-v2-title mb-4">{{ 'Contacts.V2.generalInfoTitle' | translate }}</div>

  <contact-exists-info
    *ngIf="parentForm && !(isLoading$ | async)"
    [form]="parentForm"
    [isInited]="!isEdit"
    #contactExistsInfoComponent
  ></contact-exists-info>

  <div class="row">
    <div *ngIf="isPersonal" class="col-12 col-md-6">
      <div class="control-v2 mb-4">
        <input-label [label]="'Contacts.V2.firstName' | translate" [required]="!isPreview"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.firstName)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.firstName"
          [placeholder]="'Contacts.V2.firstNamePlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div *ngIf="isPersonal" class="col-12 col-md-6">
      <div class="control-v2 mb-4">
        <input-label [label]="'Contacts.V2.lastName' | translate" [required]="!isPreview"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.lastName)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.lastName"
          [placeholder]="'Contacts.V2.lastNamePlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div *ngIf="isPersonal" class="col-12 col-md-6">
      <div class="control-v2 mb-4">
        <input-label [label]="'Contacts.V2.birth' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.birth)?.value | date: 'dd.MM.yyyy' | defaultValue }}
        </div>
        <datepicker-v2
          [hidden]="isPreview"
          [controlName]="ContactDetails.birth"
          [form]="parentForm"
          [title]="'Contacts.V2.birthPlaceholder' | translate"
          [emitControlEvent]="true"
        ></datepicker-v2>
      </div>
    </div>

    <div *ngIf="!isPersonal" [ngClass]="isPreview ? 'col-12 col-md-6' : 'col-12'">
      <div class="control-v2 mb-4">
        <input-label [label]="'Contacts.V2.companyName' | translate" [required]="!isPreview"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.companyName)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.companyName"
          [placeholder]="'Contacts.V2.companyNamePlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div [ngClass]="isPersonal || isPreview ? 'col-12 col-md-6' : 'col-12'">
      <div class="control-v2 mb-4">
        <input-label [label]="'Contacts.V2.role' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info text-capitalize">
          <ng-container *ngFor="let role of selectedFunctions; let last = last">
            {{ role.internal ? ('Contacts.Function.' + role.name | translate) : role.name
            }}<span *ngIf="!last">,</span>
          </ng-container>
          <span *ngIf="selectedFunctions.length === 0">-</span>
        </div>
        <ng-select
          [hidden]="isPreview"
          ngSelectExtension
          [formControlName]="ContactDetails.functions"
          [url]="Config.API + '/tag/type?type=contact-function'"
          [multiple]="true"
          [closeOnSelect]="false"
          bindLabel="name"
          [placeholder]="'Contacts.V2.rolePlaceholder' | translate"
          class="new-select new-select-multiple"
          [inputAttrs]="{ maxlength: '255' }"
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$">
            <svg-icon *ngIf="item$.selected" src="/assets/img/check-checked-icon.svg"></svg-icon>
            <div *ngIf="!item$.selected" class="not-selected"></div>
            <span>{{ item.internal ? ('Contacts.Function.' + item.name | translate) : item.name }}</span>
          </ng-template>
          <ng-template ng-multi-label-tmp>
            <div class="selected-items text-capitalize">
              <ng-container *ngIf="selectedFunctions.length === 1">
                <span *ngFor="let selectedFunction of selectedFunctions" class="selected-item">
                  {{
                    selectedFunction.internal
                      ? ('Contacts.Function.' + selectedFunction.name | translate)
                      : selectedFunction.name
                  }}
                </span>
              </ng-container>
              <span class="selected-number" *ngIf="selectedFunctions.length > 1">
                {{ 'Global.selected' | translate: { count: selectedFunctions.length } }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="d-flex flex-column w-100">
      <input-label class="col" [label]="'Contacts.V2.tags' | translate"></input-label>

      <div class="d-flex flex-wrap">
        <div class="col-12 col-md-6" [hidden]="isPreview">
          <ng-select
            #select
            ngSelectExtension
            class="new-select tags-select"
            bindLabel="name"
            bindValue="value"
            [ngClass]="{ disabled: tagsLoading }"
            [loading]="tagsLoading"
            [addTagText]="'Contacts.V2.addTag' | translate"
            [formControlName]="ContactDetails.tagIds"
            [placeholder]="'Contacts.V2.tagsPlaceholder' | translate"
            [items]="tags"
            [addTag]="true"
            [multiple]="true"
            [closeOnSelect]="false"
            (scrollToEnd)="fetchMoreTags()"
            [enableSort]="false"
            [addItems]="addTag$"
            [addNewItemFunction]="addTagFn.bind(this)"
            (search)="onSearch($event)"
            [inputAttrs]="{ maxlength: '200' }"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear"></ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$">
              <svg-icon *ngIf="item$.selected" src="/assets/img/check-checked-icon.svg"></svg-icon>
              <div *ngIf="!item$.selected" class="not-selected"></div>
              <span>{{ item.name }}</span>
              <button *ngIf="!item.isPredefined" (click)="removeTagFromApi(item.value, $event)">
                <svg-icon src="/assets/img/ic-trash-red-v2.svg"></svg-icon>
              </button>
            </ng-template>
          </ng-select>
        </div>
        <div [ngClass]="isPreview ? 'col-12' : 'col-12 col-md-6 mt-3 mt-md-0'">
          <div class="tags-container" [ngClass]="{ disabled: isPreview }">
            <div class="pill-tag" *ngFor="let tag of selectedTags">
              {{ tag.label }}
              <button (click)="removeSelectedTag(tag.value.value)">
                <svg-icon src="/assets/img/ic-close-white-v2.svg"></svg-icon>
              </button>
            </div>
            <span *ngIf="isPreview && selectedTags.length === 0" class="control-v2-info">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
