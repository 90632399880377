import { Subscription } from 'rxjs';
import { Config } from '@shared/configs/config';
import { CONTACT_FORM } from '@modules/contacts/shared/consts/contact-form.const';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormControl, FormArray } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgOption } from '@ng-select/ng-select';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { ContactServiceV2 } from '@shared/modules/contact-sidenav/services/contact-v2.service';
import { ContactTag } from '@modules/contacts/shared/interfaces/contact-tag.interface';
import { ConfirmationDiscardModalComponent } from '../confirmation-discard-modal/confirmation-discard-modal.component';

interface ContactModalV2Data {
  contact: Contact;
  hideResponsible: boolean;
  firstName?: string;
}

@Component({
  selector: 'app-contact-modal-v2',
  templateUrl: './contact-modal-v2.component.html',
  styleUrls: ['./contact-modal-v2.component.scss']
})
export class ContactModalV2Component implements OnInit, OnDestroy {
  CONFIG = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  NEW_CONTACT_FORM = CONTACT_FORM;

  form: FormGroup;

  sub: Subscription = new Subscription();
  subTypeChange: Subscription;

  loading: boolean = false;
  contact: Contact = null;

  contactTypeList: NgOption[] = [
    {
      id: ContactType.PERSONAL,
      label: this.t.instant(`Contacts.Type.${ContactType.PERSONAL}`)
    },
    {
      id: ContactType.COMPANY,
      label: this.t.instant(`Contacts.Type.${ContactType.COMPANY}`)
    }
  ];

  arrayFormControls: string[] = [
    CONTACT_FORM.tagIds,
    CONTACT_FORM.functions,
    CONTACT_FORM.communications,
    CONTACT_FORM.links
  ];

  get modalTitle(): string {
    return this.data?.contact?.id
      ? 'Contacts.V2.Connections.editContactModalTitle'
      : 'Contacts.V2.Connections.newContactModalTitle';
  }

  get selectedFunctions(): string[] {
    return this.form.get(CONTACT_FORM.functions).value;
  }

  get isCompany(): boolean {
    return this.form.get(CONTACT_FORM.type).value === ContactType.COMPANY;
  }

  get isPersonal(): boolean {
    return this.form.get(CONTACT_FORM.type).value === ContactType.PERSONAL;
  }

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactModalV2Component>,
    private service: ContactServiceV2,
    private changes: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: ContactModalV2Data
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setFormData();
    this.subscribeTypeChange();
    this.dialogCloseDetection();
  }

  dialogCloseDetection() {
    const sub = this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });

    this.sub.add(sub);
  }

  initForm(): void {
    const group = {} as Record<string, FormControl | FormArray>;
    Object.keys(CONTACT_FORM).forEach((key) => {
      group[key] = this.arrayFormControls.includes(key) ? this.fb.control([]) : this.fb.control(null);
    });
    this.form = this.fb.group(group);
    this.setInitialValues();
    this.setValidatorsByType(ContactType.PERSONAL);
  }

  subscribeTypeChange(): void {
    this.subTypeChange = this.form
      .get(CONTACT_FORM.type)
      .valueChanges.subscribe((type: ContactType) => this.setValidatorsByType(type));
  }

  private setInitialValues(): void {
    this.form.get(CONTACT_FORM.type).setValue(ContactType.PERSONAL);
    this.form.get(CONTACT_FORM.isNetPrices).setValue(false);
    this.form.get(CONTACT_FORM.firstName).setValue('');
    this.form.get(CONTACT_FORM.lastName).setValue('');
  }

  private setFormData(): void {
    if (this.data.firstName) {
      this.form.get(CONTACT_FORM.firstName).setValue(this.data.firstName);
      this.form.get(CONTACT_FORM.firstName).markAsDirty();
    }

    if (!this.data?.contact) return;

    this.contact = new Contact(this.data?.contact);

    Object.keys(this.contact).forEach((key) => {
      const control = this.form.get(key);
      if (control) control.setValue(this.contact[key]);
      if (key === CONTACT_FORM.isNetPrices) control.setValue(this.contact[key] ?? false);
    });

    this.setContactArrays();
    this.setValidatorsByType(this.contact.type);
  }

  private setContactArrays(): void {
    this.form.get(CONTACT_FORM.functions).setValue(this.contact?.function || []);
    this.form.get(CONTACT_FORM.tagIds).setValue(this.contact?.tags?.map((t: ContactTag) => t.id) || []);
    this.form.get(CONTACT_FORM.links).setValue(this.contact?.links || []);
    this.form.get(CONTACT_FORM.communications).setValue(this.contact?.communications || []);
  }

  private setValidatorsByType(type: ContactType): void {
    switch (type) {
      case ContactType.COMPANY:
        this.setCompanyValidators();
        break;
      default:
        this.setPersonalValidators();
        break;
    }

    this.setSingleValidator(CONTACT_FORM.availability, [Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.email, [Validators.email, Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.acquisitionSource, [Validators.maxLength(255)]);
    this.changes.detectChanges();
  }

  private setPersonalValidators(): void {
    this.setSingleValidator(CONTACT_FORM.firstName, [Validators.required, Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.lastName, [Validators.required, Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.companyName, [Validators.maxLength(255)]);
  }

  private setCompanyValidators(): void {
    this.setSingleValidator(CONTACT_FORM.companyName, [Validators.required, Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.firstName, [Validators.maxLength(255)]);
    this.setSingleValidator(CONTACT_FORM.lastName, [Validators.maxLength(255)]);
  }

  private setSingleValidator(name: string, validators: ValidatorFn[]): void {
    const control = this.form.get(name);
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  submitContact(): void {
    Object.keys(CONTACT_FORM).forEach((key) => {
      const control = this.form.get(key);
      control.markAsTouched();
      control.updateValueAndValidity();
    });

    if (this.loading || this.form.invalid) return;

    this.loading = true;
    const payload = {
      ...this.form.getRawValue(),
      functions: this.form.get(CONTACT_FORM.functions).value.map((f) => f.name)
    };

    payload.id ? this.updateContact(payload) : this.createContact(payload);
  }

  createContact(value): void {
    this.service.saveContact(value).subscribe(
      (res) => this.onSuccessSaveContact(res),
      () => this.s.error(this.t.instant('Contacts.V2.Manage.errorCreatingContact'))
    );
  }

  updateContact(value): void {
    this.service.updateContact(this.contact.id, value).subscribe(
      (res) => this.onSuccessSaveContact(res),
      () => this.s.error(this.t.instant('Contacts.V2.Manage.errorCreatingContact'))
    );
  }

  onSuccessSaveContact(res: Contact): void {
    this.loading = false;
    this.dialogRef.close(res);
    this.s.success(
      this.t.instant(
        `Contacts.V2.Manage.${this.data.contact?.id ? 'successEditingContact' : 'successCreatingContact'}`
      )
    );
  }

  cancel(): void {
    console.log(this.form);
    if (this.form.pristine) {
      this.dialogRef.close();
    } else {
      let dialogRef = this.dialog.open(ConfirmationDiscardModalComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        switch (confirmed) {
          case true:
            this.dialogRef.close();
            break;
          default:
            break;
        }
      });
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.subTypeChange?.unsubscribe();
  }
}
