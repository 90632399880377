<div
  class="sidenav-title d-flex flex-row align-items-center justify-content-between flex-md-row"
  [ngClass]="[wrap ? 'sidenav-title-wrap' : '', customClass]"
>
  <div class="d-flex align-items-center w-100 w-sm-auto">
    <div
      class="sidenav-title-close mr-3 align-content-center justify-content-center"
      (click)="!!closeFunc ? closeFunc() : sidenav.close()"
    >
      <img draggable="false" src="/assets/img/hide-icon.svg" alt="'Global.hideSidenav'" />
    </div>
    <h2
      *ngIf="title"
      class="mr-3"
      [ngClass]="{ smaller: isRemoveEnabled && isEditEnabled }"
      [title]="title"
      [innerHTML]="title"
    ></h2>
    <ng-container *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-container>
  </div>
  <div class="sidenav-title-actions">
    <div
      *ngIf="isRemoveEnabled"
      class="sidenav-title-edit align-content-center justify-content-center"
      (click)="removeClick.emit()"
    >
      <img draggable="false" src="/assets/img/ic-trash-red-v2.svg" alt="Remove" />
    </div>
    <div
      *ngIf="isEditEnabled"
      class="sidenav-title-edit align-content-center justify-content-center"
      (click)="editClick.emit()"
    >
      <img draggable="false" src="/assets/img/pencil-icon.svg" alt="Edit" />
    </div>
  </div>

  <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
</div>
