<modal-title [title]="'ProjectTeam.Manage.add' | translate" [closeCallback]="cancel.bind(this)"></modal-title>
<!-- #region Content -->

<form [formGroup]="form" class="project-team-manage">
  <p>{{ 'ProjectTeam.Manage.addDescription' | translate }}</p>
  <mat-dialog-content [ngClass]="{ 'project-team-manage--min-height': isMinHeight }">
    <ng-select
      ngSelectExtension
      #contactSearchSelect="ngSelectExtensionDir"
      [url]="Config.API + '/contact/search'"
      class="w-100 select select-default mb-3"
      [placeholder]="('ProjectTeam.Manage.addTeamMembers' | translate) + '*'"
      [loadingText]="'ProjectTeam.Manage.loadingContacts' | translate"
      [notFoundText]="'ProjectTeam.Manage.notFoundContacts' | translate"
      [addTagText]="'ProjectTeam.Manage.addContact' | translate"
      [formControlName]="'user'"
      [bindLabel]="'name'"
      [hideSelected]="true"
      [multiple]="true"
      (change)="onUserSelectChange()"
      [closeOnSelect]="false"
      [addTag]="validateEmail.bind(this)"
      [tabIndex]="-1"
    >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <contact-option-template [contact]="item"></contact-option-template>
      </ng-template>
    </ng-select>
    <span
      *ngIf="form?.get('user')?.errors && !form?.get('user')?.value && form?.get('user')?.touched"
      class="d-flex form-control-error-msg mb-1"
      [innerHTML]="'ProjectTeam.noTeamMember' | translate"
    ></span>

    <gbxsoft-input
      [errMessages]="errorMessages('position')"
      [formControlName]="'position'"
      [config]="{ name: 'ProjectTeam.Manage.position' | translate, type: 'text', placeholder: '' }"
    ></gbxsoft-input>

    <gbxsoft-select
      #roleSelect
      class="select"
      [errMessages]="errorMessages('role')"
      [formControlName]="'role'"
      [config]="positionSelectConfig"
      [options]="positionSelectOptions"
      *ngIf="isNecessaryToChoseRole"
    ></gbxsoft-select>

    <gbxsoft-input
      [permission]="{ group: PermissionsGroups.PROJECT_TEAM, action: 'HOURLY' }"
      [errMessages]="errorMessages('hourlyRate')"
      [formControlName]="'hourlyRate'"
      [config]="{
        name: 'ProjectTeam.Manage.hourlyRate' | translate,
        type: 'number',
        appendContent: currencySymbol,
        placeholder: ''
      }"
    ></gbxsoft-input>
  </mat-dialog-content>
</form>

<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    (onClick)="cancel()"
    [class]="'mr-2 bold'"
    [config]="{
      text: 'ProjectTeam.Manage.cancel' | translate,
      type: ButtonTypes.DISCARD_BUTTON,
      size: ButtonSize.LARGE
    }"
  ></app-button>
  <app-button
    [disabled]="loading"
    [loading]="loading"
    (onClick)="submit()"
    [config]="{
      text: 'ProjectTeam.Manage.invite' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-plus-add.svg'
    }"
  ></app-button>
</mat-dialog-actions>
<!-- #endregion -->
