import { NavigateService } from '@shared/services/navigate.service';
import { StorageService } from '@core/services/storage.service';
import { TaskType } from '@shared/enums/task-type.enum';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TaskSidenavManager } from '@shared/modules/task-sidenav/managers/task-sidenav.manager';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Task } from '@shared/models/task.model';
import { Config } from '@shared/configs/config';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService
} from '@shared/modules/list/services/list-task.service';
import { TaskController } from '@shared/controllers/task.controller';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { Project } from '@modules/projects/shared/models/project.model';

@Component({
  selector: 'task-basic-preview-data',
  templateUrl: './task-basic-preview-data.component.html',
  styleUrls: ['./task-basic-preview-data.component.scss']
})
export class TaskBasicPreviewDataComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  Config = Config;
  TaskType = TaskType;

  sub: Subscription = new Subscription();
  taskManager: TaskSidenavManager = new TaskSidenavManager();
  taskPermission: TaskPermissionController = new TaskPermissionController();

  get shouldShowProjectInfo(): boolean {
    return (
      !!this.service?.task?.assignedTo?.[0]?.userPerson?.previewName ||
      !!this.service?.task?.assignedEmails?.length ||
      !!this.service?.task?.project?.fullAddress
    );
  }

  get assignedDisplayName(): string {
    return (
      this.service?.task?.assignedTo?.[0]?.userPerson?.previewName ||
      this.service?.task?.assignedEmails?.[0] ||
      ''
    );
  }

  constructor(
    public storage: StorageService,
    public service: TaskSidenavService,
    private changes: ChangeDetectorRef,
    private listTaskService: ListTaskService,
    private navigateService: NavigateService
  ) {}

  ngOnInit() {
    this.listenListTaskEvents();
    this.subscribeTaskUpdate();
    this.initPermissions();
  }

  initPermissions() {
    this.taskPermission.initPermissions(this.service.task);
    this.changes.detectChanges();
  }

  listenListTaskEvents() {
    const actions = [ListTaskEventType.UPDATE_TASK];
    const sub = this.listTaskService.emitter
      .pipe(filter((i) => actions.indexOf(i.type) > -1))
      .subscribe(this.updateTask.bind(this));
    this.sub.add(sub);
  }

  updateTaskFromCheckbox(task: Task) {
    const ctrl = new TaskController(task);
    ctrl.changeTaskStatus(task).subscribe(() => {
      this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
    });
    // this.updateChildrenInParentTask();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_TASK, data: task });
    this.changes.detectChanges();
  }

  updateTask(e: ListTaskEvent) {
    if (e.data.id === this.service.task.id) {
      this.service.initPreviewState(e.data);
      this.service.form.get(TASK_SIDENAV_FORM.status).patchValue(e.data.status, { emitEvent: false });
      this.changes.detectChanges();
    }
  }

  onStatusChange(task: Task) {
    this.service.setStatusCompleted(task);
  }

  checkedSubtasks(checked: boolean) {}

  subscribeTaskUpdate() {
    const sub = this.service.taskActionEmitter.subscribe(() => this.changes.detectChanges());
    this.sub.add(sub);
  }

  openTaskEdit() {
    this.taskManager.editTask(this.service?.task);
  }

  openParentPreview(task: Task) {
    this.taskManager.previewTask(task);
  }

  navigateToProject(project?: Project) {
    if (!project) return;

    this.navigateService
      .goWithPromise('project-preview-summary', { projectId: project?.id })
      .then(() => this.service.closeTaskSidenav());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
