import { Config } from '@shared/configs/config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppInjector } from '@shared/services/app-injector.service';
import { Injector } from '@angular/core';
import { NavigateService } from '@shared/services/navigate.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { ContactRemoveAccountModalComponent } from '@modules/contacts/shared/components/contact-remove-account-modal/contact-remove-account-modal.component';
import { switchMap } from 'rxjs/operators';
import { ContactModalComponent } from '@shared/components/contact-modal/contact-modal.component';
import {
  ContactProjectPemissionDialogData,
  ContactProjectPermissionModalComponent
} from '@shared/components/contact-project-permission-modal/contact-project-permission-modal.component';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ContactSidenavComponent } from '@shared/modules/contact-sidenav/components/contact-sidenav/contact-sidenav.component';

export class ContactController {
  n: NavigateService;
  s: SnackBarService;
  t: TranslateService;
  contactService: ContactService;
  injector: Injector;
  dialog: MatDialog;
  sidenav: SidenavService;

  sidenavConfig = { maxWidth: '927px', hideBackdrop: true };

  constructor(
    private contact?: Contact,
    public objectCreatorId?: number[]
  ) {
    this.injector = AppInjector.getInjector();
    this.n = this.injector.get(NavigateService);
    this.s = this.injector.get(SnackBarService);
    this.t = this.injector.get(TranslateService);
    this.contactService = this.injector.get(ContactService);
    this.dialog = this.injector.get(MatDialog);
    this.sidenav = this.injector.get(SidenavService);
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'ADD',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  add(queryParams?: any, type?: ContactType) {
    this.sidenav.open(ContactSidenavComponent, this.sidenavConfig);
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'ADD',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  addQuickContactApi(contactForm: any) {
    return this.contactService.setContact(contactForm);
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'ADD',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  addQuickContact(data?: any): MatDialogRef<ContactModalComponent> {
    const dialog = this.dialog.open(ContactModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        contact: { draft: !!data.draft },
        projectId: data?.projectId || null,
        firstName: data?.firstName
      },
      disableClose: true
    });
    return dialog;
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'ADD',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  addQuickSubContact(contact: Contact, isDraft: boolean = false): MatDialogRef<ContactModalComponent> {
    const dialog = this.dialog.open(ContactModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: { parentContact: contact, isDraft },
      disableClose: true
    });
    return dialog;
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  editMainContactOrResponsible(data: ContactProjectPemissionDialogData) {
    const dialog = this.dialog.open(ContactProjectPermissionModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data
    });
    return dialog;
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  editQuickContact(hideResponsible: boolean = false, projectId: number): MatDialogRef<ContactModalComponent> {
    const dialog = this.dialog.open(ContactModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: { contact: this.contact, hideResponsible, projectId: projectId || null },
      disableClose: true
    });
    return dialog;
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'ADD',
    objectCreatorIdMethod: (self) => self?.contact?.creator?.id
  })
  addSubcontact(id: number) {
    this.sidenav.open(ContactSidenavComponent, this.sidenavConfig, { id, selectedTab: 'connections' });
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  edit(contact?: Contact) {
    this.editContact(contact);
  }

  editContact(contact?: Contact) {
    if (!contact && this.contact) {
      contact = this.contact;
    } else if (!contact) {
      console.error('ERROR Error: No contact in Contact Controller or in method edit');
      return;
    }
    this.sidenav.open(ContactSidenavComponent, this.sidenavConfig, { id: contact.id, isPreview: false });
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'PREVIEW',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  preview(id) {
    this.sidenav.open(ContactSidenavComponent, this.sidenavConfig, { id, isPreview: true });
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  remove(contact?: Contact) {
    return new Observable((observer) => {
      if (contact.employee) {
        const dialog = this.dialog.open(ContactRemoveAccountModalComponent, {
          width: Config.DEFAULT_MODAL_WIDTH,
          autoFocus: false
        });
        dialog.componentInstance.contact = new Contact(contact);

        return dialog.componentInstance.eventEmitter
          .pipe(switchMap(() => this.removeAction(contact.id)))
          .subscribe(
            (res) => {
              this.onRemoveSuccess(res, observer);
            },
            (error) => this.onRemoveError.bind(this)
          );
      } else {
        return this.removeAction(contact.id).subscribe(
          (res) => {
            this.onRemoveSuccess(res, observer);
          },
          (error) => this.onRemoveError.bind(this)
        );
      }
    });
  }

  private removeAction(id: number) {
    return this.contactService.remove(id);
  }

  @Permission({
    group: PermissionsGroups.CONTACTS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self) => self?.objectCreatorId
  })
  removeContactFromProject(id: number) {
    return new Observable((observer) => {
      return this.removeActionFromProject(id).subscribe(
        (res) => {
          this.onRemoveSuccess(res, observer);
        },
        () => this.onRemoveError.bind(this)
      );
    });
  }

  private removeActionFromProject(id: number) {
    return this.contactService.removeContactFromProject(id);
  }

  private onRemoveSuccess(res, observer: Subscriber<any>) {
    this.s.success(this.t.instant('Contacts.Fields.successContactRemoved'));
    observer.next(res);
    observer.complete();
  }

  private onRemoveError() {
    this.s.error(this.t.instant('Contacts.Fields.errorContactRemoved'));
  }
}
