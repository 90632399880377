import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Config } from '@shared/configs/config';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { HttpError } from '@shared/interfaces/error.interface';
import { ConfirmationDiscardModalComponent } from '@shared/components/confirmation-discard-modal/confirmation-discard-modal.component';

@Component({
  selector: 'project-contacts-add-modal',
  templateUrl: './project-contacts-add-modal.component.html',
  styleUrls: ['./project-contacts-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectContactsAddModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  Config = Config;

  form: FormGroup = new FormGroup({
    contact: new FormControl(null, [Validators.required])
  });
  loading: boolean = false;
  onChooseContact: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ProjectContactsAddModalComponent>,
    public projectPreviewService: ProjectPreviewService,
    private projectAPIService: ProjectAPIService,
    private s: SnackBarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  ngOnInit(): void {}

  onCreateContact(e) {
    this.dialogRef.close();
    const ctrl = new ContactController();
    const sub = ctrl
      .addQuickContact({
        projectId: this.projectPreviewService.project.id,
        firstName: e
      })
      .componentInstance.onAddContact.subscribe(() => {
        this.onChooseContact.emit(true);
        sub.unsubscribe();
      });
  }

  cancel(): void {
    if (this.form.pristine) {
      this.dialogRef.close();
    } else {
      let dialogRef = this.dialog.open(ConfirmationDiscardModalComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        switch (confirmed) {
          case true:
            {
              this.dialogRef.close();
            }
            break;
          default:
            break;
        }
      });
    }
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.loading) {
      return;
    }
    this.loading = true;

    this.projectAPIService
      .addContactToProject(this.form.get('contact').value, this.projectPreviewService.project.id)
      .subscribe({
        next: () => {
          this.onChooseContact.emit(true);
          this.s.success(this.t.instant('ProjectContacts.Add.contactAdded'));
          this.form.reset();
          this.dialogRef.close();
        },
        error: (error: HttpError) => {
          this.s.error(this.t.instant('ProjectContacts.Add.errorAddingContact'));
        }
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }
}
